@use '../abstract' as *

$header_shadow: 0 0 40px rgba(#3B4156, .08)

.header_container
	z-index: 10
	
	@include correctTransformGlitch
	
	position: absolute
	inset: 0
	width: 100%
	height: fit-content
	
	pointer-events: none
	
	.header
		@include correctTransformGlitch
		
		pointer-events: all
		
		padding-top: 2.25rem
		@include z-padding-inline
		padding-inline: min(var(--padding-inline-value), 7.5%)
		
		.default_header
			display: flex
			flex-direction: row
			gap: 4rem
			justify-content: space-between
			
			@include z-typo-paragraph-inter-tight-semibold-20-24
			
			.right
				text-align: center
				
				.menu
					display: flex
					flex-direction: row
					justify-content: center
					align-items: center
					gap: 50px
					
					.menu_item_container
						position: relative
						display: flex
						
						.menu_item
							position: relative
							display: flex
							
							.line
								width: 100%
								position: absolute
								top: 100%
								
								svg
									width: 100%
							
							&.active
								color: $color-orange-principal
			
			.our_ecosystem
				padding: 1rem 1.5rem
				
				border-radius: 999px
				background-color: $color-orange-principal
				color: white
				
				display: flex
				flex-direction: row
				gap: 15px
				justify-content: center
				align-items: center
				
				svg
					width: auto
					max-height: 25px
					stroke: white
		
		@include zi-media('>', tablet)
			.logo_container
				box-shadow: $header_shadow
				
				padding: 1rem 50px
				border-radius: 999px
				background-color: white
				max-width: 16rem
				
				display: flex
				justify-content: center
				align-items: center
				
				svg
					width: 100%
					//height: fit-content
					height: auto
					
					transform: scale(.95)
					
					transition: transform .3s ease
				
				&:hover
					svg
						transform: scale(1.05)
			
			.default_header
				.right
					display: flex
					flex-direction: row
					gap: 30px
					
					.menu
						box-shadow: $header_shadow
						
						padding: 1rem 50px
						
						border-radius: 999px
						background-color: white
						
						.menu_item_container
							position: relative
							
							.menu_item
								.title
									transition: transform .3s ease
							
							.sub_menu
								display: none
								
								position: absolute
								top: 100%
								
								left: 50%
								transform: translateX(-50%)
								
								.sub_menu_container
									box-shadow: 0 0 40px rgba(#3B4156, .08)
									
									background: white
									border-radius: 10px
									margin-top: 36px
									
									padding: 20px 25px
									
									width: fit-content
									white-space: nowrap
									
									display: flex
									flex-direction: column
									gap: .5rem
							
							&:hover
								.title
									transform: translateY(-4px)
								
								.sub_menu
									display: block
			
			@media (max-width: 1750px)
				.logo_container
					max-width: 180px
					padding: 1rem 2rem
				
				.default_header
					gap: 2rem
					
					@include z-typo-paragraph-inter-tight-semibold-15-19
					
					.right
						
						.menu
							gap: 2rem
			@media (max-width: 1500px)
				.default_header
					.right
						.menu
							gap: 1rem
							padding: 1rem 2rem
		
		@include zi-media
			.small_screen_header
				display: flex
				flex-direction: row
				gap: 4rem
				
				justify-content: space-between
				align-items: center
				
				width: 100%
				
				.logo_container
					padding: .75rem 1.5rem
					display: flex
					justify-content: center
					align-items: center
					
					border-radius: 999px
					background-color: white
					
					box-shadow: $header_shadow

					.logo_small
						height: 20px
						width: auto

				.menu_handler
					padding: .75rem 1.5rem
					border-radius: 999px
					background-color: white
					
					display: flex
					justify-content: center
					align-items: center
					
					box-shadow: $header_shadow
			
			.default_header_container_overflow
				position: fixed
				top: 0
				left: 0
				
				width: 100%
				height: 100vh
				
				overflow: hidden
				
				pointer-events: none
			
			.default_header_container
				position: absolute
				inset: 0
				padding-left: 10%
				
				width: 100%
				height: 100vh
				
				background: rgba($color-bleu-principal, .5)
				
				transition: left .3s ease
				
				pointer-events: all
				
				&:not(.mobile_shown)
					left: 100%
				
				.default_header
					height: 100%
					background-color: $color-bleu-principal
					
					display: flex
					flex-direction: column
					gap: 2rem
					
					color: white
					
					padding: 2rem 3rem
					
					@include z-typo-paragraph-inter-tight-semibold-25-30
					
					.logo_container
						padding: .75rem 1.5rem
						display: flex
						justify-content: center
						align-items: center

						.logo_small
							height: 20px
							width: auto

							path
								fill: white

					.right
						display: flex
						flex-direction: column
						gap: 3rem
						
						text-align: left
						white-space: nowrap
						
						.menu
							flex-direction: column
							gap: 1.5rem
							align-items: flex-start
							
							.menu_item_container
								display: flex
								flex-direction: column
								gap: 1rem
								align-items: flex-start
								
								.menu_item
									&.has_submenu
										pointer-events: none
									
									.title
										pointer-events: all
										display: flex
										flex-direction: row
										gap: 1rem
										justify-content: center
										align-items: center
										
										.icon
											pointer-events: none
											font-size: 1.5rem
											transition: transform .3s ease
									
									.line
										svg
											width: 100%
											height: auto
								
								.sub_menu_container
									z-index: 1
									pointer-events: all
									
									visibility: hidden
									display: flex
									flex-direction: column
									gap: .5rem
									align-items: flex-start
									
									@include z-typo-paragraph-inter-tight-light-20-24
								
								&.opened
									.title
										.icon
											transform: rotate(180deg)
									
									.sub_menu_container
										visibility: visible
					
					.our_ecosystem
						margin-right: auto
						
						display: flex
						flex-direction: row
						gap: 15px
						
						@include z-typo-paragraph-inter-tight-semibold-20-24
					
					.close_menu
						display: flex
						flex-direction: column
						align-items: center
						gap: .5rem
						
						@include z-typo-paragraph-inter-tight-medium-15-19
						
						.icon
							padding: .75rem 3rem
							background-color: white
							border-radius: 999px
							color: $color-bleu-principal
							display: flex
							
							svg
								width: 24px
								height: 24px
