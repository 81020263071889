//region Colors
$color-bleu-principal: #3B4156
$color-orange-principal: #D36F3B
$color-blanc: #FFFFFF
$color-blanc-casse: #EDEDED
$color-noir: #1D1D1B
$color-turquoise-audit: #6BAF91
$color-bordeaux-conseil: #611A1E
$color-jaune-social: #F5AE39
$color-dark-blue: #2D3243
//endregion

//region Fonts
$font-family-cormorant-garamond: Cormorant Garamond
$font-family-inter-tight: Inter Tight
$font-style-italic: italic
$font-style-normal: normal
$font-weight-bold: bold
$font-weight-medium: medium
$font-size-18: 18px
$font-size-40: 40px
$font-size-50: 50px
$character-spacing-0: 0px
$line-spacing-21: 21px
$line-spacing-49: 49px
$line-spacing-60: 60px
//endregion
