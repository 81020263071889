@import 'mixins'

//region Heading
@mixin z-typo-heading-cormorant-garamond-bold-italic-50-50
	font-family: "Cormorant Garamond Bold Italic", sans-serif
	font-size: 50px
	line-height: 50px

@mixin z-typo-heading-cormorant-garamond-bold-italic-68-83
	font-family: "Cormorant Garamond Bold Italic", sans-serif
	font-size: 68px
	line-height: 83px

@mixin z-typo-heading-cormorant-garamond-bold-italic-70-85
	font-family: "Cormorant Garamond Bold Italic", sans-serif
	font-size: 70px
	line-height: 85px

@mixin z-typo-heading-cormorant-garamond-bold-italic-43-52
	font-family: "Cormorant Garamond Bold Italic", sans-serif
	font-size: 43px
	line-height: 52px

@mixin z-typo-heading-inter-tight-semibold-50-60
	font-family: Inter Tight SemiBold, sans-serif
	font-size: 50px
	line-height: 60px

@mixin z-typo-heading-inter-tight-medium-55-66
	font-family: "Inter Tight Medium", sans-serif
	font-size: 55px
	line-height: 66px

@mixin z-typo-heading-inter-tight-medium-60-72
	font-family: "Inter Tight Medium", sans-serif
	font-size: 60px
	line-height: 72px

@mixin z-typo-heading-inter-tight-medium-40-49
	font-family: "Inter Tight Medium", sans-serif
	font-size: 40px
	line-height: 49px

@mixin z-typo-heading-inter-tight-medium-37-45
	font-family: "Inter Tight Medium", sans-serif
	font-size: 37px
	line-height: 45px

@mixin z-typo-heading-inter-tight-light-37-45
	font-family: "Inter Tight Light", sans-serif
	font-size: 37px
	line-height: 45px

@mixin z-typo-heading-inter-tight-light-28-35
	font-family: "Inter Tight Light", sans-serif
	font-size: 28px
	line-height: 35px

@mixin z-typo-heading-inter-tight-light-20-24
	font-family: "Inter Tight Light", sans-serif
	font-size: 20px
	line-height: 24px

@mixin z-typo-heading-inter-tight-bold-40-49
	font-family: "Inter Tight Bold", sans-serif
	font-size: 40px
	line-height: 49px

@mixin z-typo-heading-inter-tight-semibold-40-49
	font-family: "Inter Tight SemiBold", sans-serif
	font-size: 40px
	line-height: 49px

@mixin z-typo-heading-inter-tight-bold-35-42
	font-family: "Inter Tight Bold", sans-serif
	font-size: 35px
	line-height: 42px

@mixin z-typo-heading-inter-tight-bold-28-35
	font-family: "Inter Tight Bold", sans-serif
	font-size: 28px
	line-height: 35px

@mixin z-typo-heading-inter-tight-bold-62-75
	font-family: "Inter Tight Bold", sans-serif
	font-size: 62px
	line-height: 75px
//endregion
//region Paragraph
@mixin z-typo-paragraph-inter-tight-light-20-24
	font-family: Inter Tight Light, sans-serif
	font-size: 20px
	line-height: 24px

@mixin z-typo-paragraph-inter-tight-medium-20-24
	font-family: Inter Tight Medium, sans-serif
	font-size: 20px
	line-height: 24px

@mixin z-typo-paragraph-inter-tight-semibold-20-24
	font-family: Inter Tight SemiBold, sans-serif
	font-size: 20px
	line-height: 24px

@mixin z-typo-paragraph-cormorant-garamond-medium-italic-20-24
	font-family: "Cormorant Garamond Medium Italic", sans-serif
	font-size: 20px
	line-height: 24px

@mixin z-typo-paragraph-cormorant-garamond-bold-italic-20-24
	font-family: "Cormorant Garamond Bold Italic", sans-serif
	font-size: 20px
	line-height: 24px

@mixin z-typo-paragraph-cormorant-garamond-bold-italic-25-30
	font-family: "Cormorant Garamond Bold Italic", sans-serif
	font-size: 25px
	line-height: 30px

@mixin z-typo-paragraph-inter-tight-light-18-21
	font-family: "Inter Tight Light", sans-serif
	font-size: 18px
	line-height: 21px

@mixin z-typo-paragraph-inter-tight-medium-18-21
	font-family: "Inter Tight Medium", sans-serif
	font-size: 18px
	line-height: 21px

@mixin z-typo-paragraph-inter-tight-semibold-18-21
	font-family: "Inter Tight SemiBold", sans-serif
	font-size: 18px
	line-height: 21px

@mixin z-typo-paragraph-inter-tight-bold-18-21
	font-family: "Inter Tight Bold", sans-serif
	font-size: 18px
	line-height: 21px

@mixin z-typo-paragraph-inter-tight-medium-15-19
	font-family: "Inter Tight Medium", sans-serif
	font-size: 15px
	line-height: 19px

@mixin z-typo-paragraph-inter-tight-semibold-15-19
	font-family: "Inter Tight SemiBold", sans-serif
	font-size: 15px
	line-height: 19px

@mixin z-typo-paragraph-inter-tight-light-15-19
	font-family: "Inter Tight Light", sans-serif
	font-size: 15px
	line-height: 19px

@mixin z-typo-paragraph-inter-tight-medium-25-30
	font-family: "Inter Tight Medium", sans-serif
	font-size: 25px
	line-height: 30px

@mixin z-typo-paragraph-inter-tight-semibold-25-30
	font-family: "Inter Tight SemiBold", sans-serif
	font-size: 25px
	line-height: 30px

@mixin z-typo-paragraph-inter-tight-bold-25-30
	font-family: "Inter Tight Bold", sans-serif
	font-size: 25px
	line-height: 30px

@mixin z-typo-paragraph-inter-tight-semibold-30-36
	font-family: "Inter Tight SemiBold", sans-serif
	font-size: 30px
	line-height: 36px

@mixin z-typo-paragraph-inter-tight-semibold-28-35
	font-family: "Inter Tight Bold", sans-serif
	font-size: 28px
	line-height: 35px

@mixin z-typo-paragraph-cormorant-garamond-bold-italic-22-26
	font-family: "Cormorant Garamond Bold Italic", sans-serif
	font-size: 22px
	line-height: 26px

@mixin z-typo-paragraph-cormorant-garamond-bold-italic-26-31
	font-family: "Cormorant Garamond Bold Italic", sans-serif
	font-size: 26px
	line-height: 31px

@mixin z-typo-paragraph-cormorant-garamond-bold-italic-24-29
	font-family: "Cormorant Garamond Bold Italic", sans-serif
	font-size: 24px
	line-height: 29px

@mixin z-typo-paragraph-cormorant-garamond-bold-italic-30-37
	font-family: "Cormorant Garamond Bold Italic", sans-serif
	font-size: 30px
	line-height: 37px

@mixin z-typo-paragraph-cormorant-garamond-bold-italic-32-39
	font-family: "Cormorant Garamond Bold Italic", sans-serif
	font-size: 32px
	line-height: 39px
//endregion
