//region Section
@mixin section()
	background: white
	padding: 20px 30px
	box-shadow: 2px 2px 8px rgba(black, .2)

@mixin shop-shadow($inset: false)
	@if $inset
		box-shadow: inset 2px 2px 8px rgba(0, 0, 0, 0.2)
	@else
		box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.2)
//endregion
//region @MEDIA
/// Function to get a value in a map from a given key. If the key isn't contained in the map, returns a default value (map item at index: $index_default)
///
/// @param $map: Map containing items
/// @param $key: Key of the item
/// @param $index_default: index of the default item if the key isn't found
///
/// @return Map value corresponding to the given key or the default item value defined by the default index
@function zi-map-get($map, $key, $index_default: 1)
	// If the key exists in the map : return the value associated
	// Else, return the map's item at index: $index_default
	@if (map-has-key($map, $key))
		@return map-get($map, $key)
	
	@else
		@return map-get($map, nth(map-keys($map), $index_default))

// Sizes :
// Mobile       -   767px   -   mobileL
// Tablet       -   1024px   -   tablet
// Monitor      -   1920px  -   monitor
@mixin zi-media($limit: '<=', $screen: tablet)
	//region Maps
	// Map containing sizes of each screen
	$pixel-sizes: (mobile: 767px, tablet: 1024px, monitor: 1920px)
	// Map containing limits data : (width value modification / function for @media)
	$limits-map: ('<': ('value': -1px, 'function': max-width), '<=': ('value': 0px, 'function': max-width), '>=': ('value': 0px, 'function': min-width), '>': ('value': 1px, 'function': min-width))
	//endregion
	//region Current values
	// Size of the current screen (default: tablet)
	$pixel-size: zi-map-get($pixel-sizes, $screen)
	// Limit values for the defined limit (default: '<=')
	$limit_values: zi-map-get($limits-map, $limit, 2)
	//endregion
	// Update pixel-size from limit value (default: first element of the map)
	$pixel-size: $pixel-size + zi-map-get($limit_values, 'value')
	
	// Create media with the limit function and the pixel-size. (limit function: by default its the second element of the map)
	@media (#{zi-map-get($limit_values, 'function', 2)}: ($pixel-size))
		@content
//endregion

@mixin z-padding-inline
	padding-inline: var(--padding-inline-value)

@mixin z-padding-block
	padding-block: var(--padding-block-value)

@mixin z-margin-inline
	margin-inline: var(--padding-inline-value)

@mixin z-padding
	@include z-padding-inline
	@include z-padding-block

@mixin z-padding-footer
	@include z-padding-inline
	@include z-padding-block
	padding-top: calc(8rem + 4rem)

@mixin correctTransformGlitch()
	@include zi-media('>', tablet)
		will-change: transform
