@use './abstract' as *

.page_content
	min-height: 100vh
	min-height: 100dvh
	gap: 4rem
	
	display: flex
	flex-direction: column
	
	> .middle
		flex: 1
		width: 100%
		
		@include z-padding-inline
		margin-top: 10rem
		margin-bottom: 8rem
		
		align-self: center
		
		display: flex
		
		> .content
			flex: 1
			width: 100%
			min-height: 100%
			
			border-radius: 20px
			overflow: hidden
			background-color: white
			
			padding: 4rem
			
			text-align: center
			
			display: flex
			flex-direction: column
			align-items: center
			gap: 4rem
			
			@include z-typo-heading-cormorant-garamond-bold-italic-68-83
			
			position: relative
			
			.canvas_container
				canvas
					position: absolute
					inset: 0
					width: 100%
					height: 100%
					
					background: transparent !important
					
					&.matter_canvas
						z-index: 0
					
					&.pixi_canvas
						z-index: 1
						pointer-events: none
			
			> *
				z-index: 2
			
			.logo
				max-width: 16rem
				pointer-events: none
			
			> .middle
				display: flex
				flex-direction: column
				align-items: center
				gap: 1.5rem
				
				pointer-events: none
				
				.error_404
					@include z-typo-heading-cormorant-garamond-bold-italic-50-50
					color: $color-orange-principal
				
				.error_text
					@include z-typo-heading-inter-tight-medium-40-49
					
					display: flex
					flex-direction: column
				
				.back_to_site
					padding: 15px 25px
					border-radius: 10px
					background-color: $color-bleu-principal
					color: white
					
					display: flex
					justify-content: center
					align-items: center
					
					text-align: center
					
					@include z-typo-paragraph-inter-tight-semibold-20-24
					
					transition: background-color .2s ease
					
					pointer-events: all
					
					span
						transition: scale .2s ease
					
					&:hover
						background-color: #2D3243
						
						span
							scale: 1.1
	
	@include zi-media
		> .middle
			margin-top: 8rem
			margin-bottom: 2rem
			
			> .content
				padding: 2rem
				
				> .middle
					.error_text
						display: block
						@include z-typo-paragraph-inter-tight-medium-25-30
			
